export default [

  // *===============================================---*
  // *--------- Librart  ---------------------------------------*
  // *===============================================---*
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/library/home.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      
    },
  },
  {
    path: '/author/:id',
    name: 'author',
    component: () => import('@/views/library/author.vue'),
    meta: {
      // contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      
    },
  },
  {
    path: '/book/:id/delails',
    name: 'book-details',
    component: () => import('@/views/library/details.vue'),
    meta: {
      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/auth/user-list',
    name: 'users-list',
    component: () => import('@/views/dashboard/index.vue'),
    
  },

]